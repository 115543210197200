export const useEventState = (initialState) => {
  const [stateValue, setStateValue] = React.useState(initialState)
  const stateValueRef = React.useRef(initialState)

  React.useEffect(() => {
    stateValueRef.current = stateValue
  }, [stateValue])

  return [stateValue, setStateValue, stateValueRef]
}
