import { SplitFactory, useClient } from '@splitsoftware/splitio-react'
import { useUser } from 'src/hooks/useUser'

export const ObeSplitFactory = ({ children, isAnonymousIdReady = false }) => {
  const { isLoadingInitialState } = useUser()

  if (!isAnonymousIdReady || isLoadingInitialState) return null

  const splitSdkUserConfig = {
    core: {
      authorizationKey: process.env.NEXT_PUBLIC_SPLIT_CLIENT_API_KEY,
      key: obe.analytics.anonymousId() || obe.storage_keys.split_default_key,
    },
    scheduler: {
      impressionsRefreshRate: 20,
    },
    storage: {
      type: 'LOCALSTORAGE',
      prefix: 'OBE-SPLITIO',
    },
    // debug: true,
  }

  return (
    <SplitFactory
      config={splitSdkUserConfig}
      updateOnSdkTimedout={true}
      updateOnSdkReadyFromCache={false}
    >
      {({ isReady, isReadyFromCache, hasTimedout }) =>
        isReady || isReadyFromCache || hasTimedout ? <Wrapper>{children}</Wrapper> : null
      }
    </SplitFactory>
  )
}

const Wrapper = ({ children }) => {
  const client = useClient()

  const onBeforeUnload = React.useCallback(() => {
    client?.destroy()
  }, [client])

  React.useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [onBeforeUnload])

  return children
}

export default ObeSplitFactory
