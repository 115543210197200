const memoryStorageFactory = () => {
  const _data = {}
  return {
    getItem: (key) => (Object.prototype.hasOwnProperty.call(_data, key) ? _data[key] : null),
    removeItem: (key) => delete _data[key],
    setItem: (key, value) => (_data[key] = String(value)),
  }
}

const hasStorageType = (type) => {
  const key = '__phnglui_mglwnafh__'
  try {
    window[`${type}Storage`].setItem(key, key)
    window[`${type}Storage`].removeItem(key)
    return true
  } catch (exception) {
    return false
  }
}

const session = hasStorageType('session') ? window.sessionStorage : memoryStorageFactory()

const local = hasStorageType('local') ? window.localStorage : memoryStorageFactory()

export const storage = {
  local,
  session,
}
