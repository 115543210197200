import { storeUtmParameters } from 'src/utils/storeUtmParameters'
import { storeUtmPromoCodes } from 'src/utils/storeUtmPromoCodes'
import { storeImpactClickId } from 'src/utils/storeImpactClickId'

export const Context = React.createContext(null)

export class ContextProvider extends React.Component {
  constructor(props) {
    super(props)

    storeUtmParameters()
    storeUtmPromoCodes()
    storeImpactClickId()

    this.setUi = (uiStore) => {
      this.setState(() => ({
        uiStore,
      }))
    }

    this.updateUiStore = (key, props) => {
      this.setState((prevState) => ({
        ...prevState,
        uiStore: {
          ...prevState?.uiStore,
          [key]: {
            ...prevState?.uiStore?.[key],
            ...props,
          },
        },
      }))
    }

    this.setVideoPlayerStatus = (props = defaults.videoPlayerStatus) => {
      this.updateUiStore('videoPlayerStatus', props)
    }

    this.setClassFeedbackDialog = (props = defaults.classFeedbackDialog) => {
      this.updateUiStore('classFeedbackDialog', props)
    }

    this.setPostClassDialog = (props = defaults.postClassDialog) => {
      this.updateUiStore('postClassDialog', props)
    }

    this.setBookmarkCollections = (props = defaults.bookmarkCollections) => {
      this.updateUiStore('bookmarkCollections', props)
    }

    this.setGuestPassModal = (props = defaults.guestPassModal) => {
      this.updateUiStore('guestPassModal', props)
    }

    this.setDownloadAppModal = (props = defaults.downloadAppModal) => {
      this.updateUiStore('downloadAppModal', props)
    }

    this.setModalType = (props = defaults.modalType) => {
      this.updateUiStore('modalType', props)
    }

    this.setBookmarkVideoDialog = (props = defaults.bookmarkVideoDialog) => {
      this.updateUiStore('bookmarkVideoDialog', props)
    }

    this.setProgramRatingModal = (props = defaults.programRatingModal) => {
      this.updateUiStore('programRatingModal', props)
    }

    this.setProgramView = (props = defaults.programView) => {
      this.updateUiStore('programView', props)
    }

    this.setHubWidget = (props = defaults.hubWidget) => {
      this.updateUiStore('hubWidget', props)
    }

    this.setMarketingModulesViewed = (props = defaults.marketingModulesViewed) => {
      this.updateUiStore('marketingModulesViewed', props)
    }

    this.state = {
      setBookmarkCollections: this.setBookmarkCollections,
      setBookmarkVideoDialog: this.setBookmarkVideoDialog,
      setClassFeedbackDialog: this.setClassFeedbackDialog,
      setPostClassDialog: this.setPostClassDialog,
      setGuestPassModal: this.setGuestPassModal,
      setDownloadAppModal: this.setDownloadAppModal,
      setModalType: this.setModalType,
      setProgramRatingModal: this.setProgramRatingModal,
      setProgramView: this.setProgramView,
      setHubWidget: this.setHubWidget,
      setUi: this.setUi,
      setVideoPlayerStatus: this.setVideoPlayerStatus,
      setMarketingModulesViewed: this.setMarketingModulesViewed,
      uiStore: {
        bookmarkCollections: { ...defaults.bookmarkCollections },
        bookmarkVideoDialog: { ...defaults.bookmarkVideoDialog },
        classFeedbackDialog: { ...defaults.classFeedbackDialog },
        postClassDialog: { ...defaults.postClassDialog },
        fetchedBookmarks: { hasFetched: false },
        fetchedFollowedCollections: { hasFetched: false },
        guestPassModal: { ...defaults.guestPassModal },
        downloadAppModal: { ...defaults.downloadAppModal },
        modalType: { ...defaults.modalType },
        programRatingModal: { ...defaults.programRatingModal },
        programView: { ...defaults.programView },
        marketingModulesViewed: { ...defaults.marketingModulesViewed },
        videoPlayerStatus: { ...defaults.videoPlayerStatus },
      },
    }
  }

  render() {
    return <Context.Provider value={this.state}>{this.props.children}</Context.Provider>
  }
}

const defaults = {
  bookmarkCollections: {
    isFollowedCollection: false,
    userFollowingCollection: null,
    viewingFollowedOnly: false,
  },
  bookmarkVideoDialog: {
    activeCollection: null,
    isAddNewCollection: false,
    isBulkMove: false,
    isOpen: false,
    obeClassIds: null,
    onSuccess: Function.prototype,
    video: null,
  },
  classFeedbackDialog: {
    isOpen: false,
    newNoteAdded: false,
    showRatingsSlide: false,
    wasDismissedEarly: false,
  },
  postClassDialog: {
    isOpen: false,
  },
  guestPassModal: {
    isOpen: false,
    source: null,
  },
  downloadAppModal: {
    shouldOpen: false,
  },
  modalType: {
    type: null,
  },
  programRatingModal: {
    isOpen: false,
    isLastClassInProgram: false,
    lastClassInProgramId: null,
    submittedRating: false,
  },
  programView: {
    isOverviewOpen: true,
  },
  hubWidget: {
    isOpen: false,
  },
  marketingModulesViewed: {},
  videoPlayerStatus: {
    isReady: false,
    isFinished: false,
    isFullscreen: false,
    isLastTwoMinutes: false,
    isPaused: false,
  },
}
