import * as Sentry from '@sentry/nextjs'
import { api } from 'src/api'
import { retrieveUtmParameters } from 'src/utils/retrieveUtmParameters'

class ObeAnalytics {
  constructor() {
    this._properties = () => ({
      isLoggedIn: api.isLoggedIn,
    })
  }

  anonymousId(captureException = true) {
    try {
      if (Object.prototype.hasOwnProperty.call(window.analytics, 'user')) {
        return window.analytics.user().anonymousId()
      }
      return undefined
    } catch (error) {
      if (captureException) Sentry.captureException(error, 'warning')

      return undefined
    }
  }

  identify(userId, email) {
    if (userId && typeof window !== 'undefined') {
      window.analytics.identify(userId, { email })
      Sentry.setUser({ id: userId })
    }
  }

  alias(newId, previousId, callback) {
    if (newId && typeof window !== 'undefined') {
      window.analytics.alias(newId, previousId, callback)
    }
  }

  init(key, setIsAnonymousIdReady) {
    if (typeof window !== 'undefined') {
      window?.dataLayer?.push({
        event: 'optimize.activate',
      })

      setIsAnonymousIdReady?.(true)
      window.analytics.load(key)
    }
  }

  page(name, properties = {}) {
    if (typeof window !== 'undefined') {
      window.analytics.page(name, {
        ...this._properties(),
        ...properties,
        utmAd: retrieveUtmParameters()?.utm_ad,
        utmPromo: retrieveUtmParameters()?.utm_promo,
      })
    }
  }

  track(event = '', properties = {}) {
    if (typeof window !== 'undefined' && event) {
      window.analytics.track(event, {
        ...this._properties(),
        ...properties,
      })
    }
  }

  resetUserId() {
    if (typeof window !== 'undefined') {
      Sentry.setUser(null)
      window.analytics.reset()
    }
  }
}

const _analytics = new ObeAnalytics()

export const analytics = _analytics
