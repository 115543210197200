export default {
  title: 'obé Fitness',
  description:
    'Live, On-demand fitness classes and workouts for everyone. Fun, energetic home workouts including cardio, strength, yoga and more from obé Fitness.',
  openGraph: {
    type: 'website',
    title: 'obé Fitness',
    description:
      'Live, On-demand fitness classes and workouts for everyone. Fun, energetic home workouts including cardio, strength, yoga and more from obé Fitness.',
    url: 'https://obefitness.com',
    images: [
      {
        url: 'https://obefitness.com/facebook-share.jpg',
        width: 1078,
        height: 668,
        alt: 'obé Fitness image',
      },
    ],
  },
}
