export const ignoreErrors = [/Non-Error promise rejection captured with keys: currentTarget.*/]

const ignoredErrorDescriptions = [
  "undefined is not an object (evaluating 't.replace')",
  't.replace is not a function',
  'a.omappapi.com',
  'window._cio.page',
  '[Cloudflare Turnstile] invalid_rcV',
  '[Cloudflare Turnstile] 600010',
  '[Cloudflare Turnstile] 110600',
  '[Cloudflare Turnstile] 110510',
  '[Cloudflare Turnstile] 300010',
]

export const beforeSend = (event, hint) => {
  const error = hint?.originalException
  const eventDescription = event.exception?.values?.[0]?.value ?? ''
  // ignore OptinMonster / analyticsjs errors
  if (ignoredErrorDescriptions.some((ignored) => eventDescription.includes(ignored))) {
    return null
  }
  // ignore 'TypeError: Illegal invocation' errors from tracking scripts
  if (
    error?.message?.match(/.*Illegal invocation.*/g) &&
    error?.stack?.match(/.*(Beacon|pixel).*/g)
  ) {
    return null
  }
  // ignore 'TypeError: Failed to fetch' errors from Google scripts
  if (
    error?.message?.match(/.*Failed to fetch.*/g) &&
    error?.stack?.match(/.*viewthroughconversion.*/g)
  ) {
    return null
  }
  return event
}
