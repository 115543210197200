import { default as qs } from 'query-string'

export const routerQueryToSelectedFilters = (query, facets) =>
  Object.entries(query).reduce((selectedFilters, [tagType, slugs]) => {
    const facet = facets.find((facet) => facet.tagType === tagType)

    if (facet?.items?.length) {
      selectedFilters[tagType] = slugs
        .split(',')
        .map((value) => facet.items.find(({ slug }) => slug === value))
        .filter((value) => !!value)
    }

    return selectedFilters
  }, {})

export const routerQueryToSelectedExclusionFilters = (query) => ({
  popularMusicOnly: !!query.popularMusicOnly,
  hideCompletedClasses: !!query.hideCompletedClasses,
})

export const routerQueryToSelectedSortBy = (query) => {
  const capitalizedSortByType =
    typeof query.sort === 'string' && query.sort.charAt(0).toUpperCase() + query.sort.slice(1)

  return {
    sortingCriteria: query.sort === 'popularity' ? 'Most Popular' : capitalizedSortByType,
  }
}

export const selectedFiltersToQueryString = (
  selectedFilters,
  selectedExclusionFilters,
  search = '',
  sort = ''
) => {
  const selectedFilterSlugsByTag = Object.fromEntries(
    Object.entries(selectedFilters).reduce(
      (params, [tagType, values]) =>
        values.length ? [...params, [tagType, values?.map((value) => value.slug)]] : params,
      []
    )
  )

  const activeExclusionFilters = Object.fromEntries(
    Object.entries(selectedExclusionFilters).filter(([, value]) => !!value)
  )

  const queryString = qs.stringify(
    {
      ...activeExclusionFilters,
      ...selectedFilterSlugsByTag,
      ...(search && { search }),
      ...(sort && { sort }),
    },
    { arrayFormat: 'comma', sort: false }
  )

  return queryString.length > 0 ? `?${queryString}` : ''
}

export const routerQueryToQueryParams = (query = {}) =>
  Object.keys(query).reduce(
    (selectedFilters, facetType) => ({
      ...selectedFilters,
      [facetType]: query[facetType].split(','),
    }),
    {}
  )
