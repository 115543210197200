import { useRouter as useNextRouter } from 'next/router'

export const useRouter = () => {
  const router = useNextRouter()
  const _push = router.push

  const push = React.useCallback(
    (...args) => {
      window.clearAllTimeouts()
      _push(...args)
    },
    [_push]
  )

  return {
    ...router,
    push,
  }
}
