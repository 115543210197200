import 'core-js/features/array/flat'
import 'core-js/features/object/entries'
import 'core-js/features/promise/finally'
import 'intersection-observer'

import { ObeSplitFactory } from 'src/components/ObeSplitFactory'
import { NavigationTrackerProvider } from 'src/providers/NavigationTrackerProvider'
import { ContextProvider } from 'src/components/App/Context'
import { DefaultSeo } from 'next-seo'
import { useEventState } from 'src/hooks/useEventState'
import { useRouter } from 'next/router'
import type { ReactElement, ReactNode } from 'react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'

import Head from 'next/head'
import SEO from 'src/next-seo.config'
import { NextSeo } from 'next-seo'

import 'styles/application.scss'
import 'public/globalHelpers'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

type NextPageWithLayout<
  P = {
    err?: any
  },
  IP = P
> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

interface AppPropsWithError extends AppProps {
  err: any
  Component: NextPageWithLayout
}

const ObeApp = ({ Component, err, pageProps }: AppPropsWithError) => {
  const [isAnonymousIdReady, setIsAnonymousIdReady, isAnonymousIdReadyRef] = useEventState(false)
  const router = useRouter()

  React.useEffect(() => {
    if (window.analytics.ready) {
      window.analytics.ready(() => {
        // Adding a ts-ignore for now, it's not clear this is working as expected anyway
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.analytics.init(process.env.NEXT_PUBLIC_SEGMENT_API_KEY, setIsAnonymousIdReady)
      })
    }

    setTimeout(() => {
      if (!isAnonymousIdReadyRef.current) setIsAnonymousIdReady(true)
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const detectLogout = (targetPath: string) => {
      if (targetPath === '/logout') {
        obe.analytics.track('loggedOut', { pageName: router.asPath })
      }
    }

    router.events.on('beforeHistoryChange', detectLogout)

    return () => {
      router.events.off('beforeHistoryChange', detectLogout)
    }
  }, [router])

  const getLayout = Component.getLayout || undefined

  return (
    <React.Fragment>
      <Head>
        <meta httpEquiv='Content-Type' content='text/html; charset=UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta content='afa018d59a21d50' name='cf-2fa-verify' />
        <link rel='icon' type='image/png' href='/favicon.png' />
      </Head>
      <DefaultSeo {...SEO} />
      {(pageProps as any).seo && <NextSeo {...(pageProps as any).seo} />}
      <ObeSplitFactory isAnonymousIdReady={isAnonymousIdReady}>
        <ContextProvider>
          <NavigationTrackerProvider>
            {getLayout ? (
              getLayout(<Component {...pageProps} err={err} />)
            ) : (
              <Component {...pageProps} err={err} />
            )}
          </NavigationTrackerProvider>
        </ContextProvider>
      </ObeSplitFactory>

      {/* set a logged in flag in the global scope for 3rd parties (OptinMonster) */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
          try {
            window.obelsLoggedIn = (!!JSON.parse(window.localStorage.getItem('__obe_'))).toString()
          } catch(error) {
            window.obelsLoggedIn = 'false'
          }
        `,
        }}
      />
    </React.Fragment>
  )
}

export default ObeApp
