import axios from 'axios'
import { storage } from 'src/storage'

class ObeApi {
  constructor(key, baseURL) {
    this.key = key

    this.api = axios.create({
      baseURL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'obe-client-app': 'web',
        X_API_KEY: process.env.NEXT_PUBLIC_OBE_CLIENT_API_KEY,
      },
    })

    const authorization = this.retrieveSessionState()?.authorization

    if (authorization) {
      this.api.defaults.headers['Authorization'] = authorization
    }

    this.api.interceptors.response.use(
      (response) => {
        if (response?.headers?.['obe-total-results-count']) {
          response.totalResults = parseInt(response?.headers?.['obe-total-results-count'])
          response.page = parseInt(response?.headers?.['obe-page'])
          response.perPage = parseInt(response?.headers?.['obe-per-page'])
        } else if (response?.headers?.total_results_count) {
          // @todo remove once backend changes are live
          response.totalResults = parseInt(response?.headers?.total_results_count)
          response.page = parseInt(response?.headers?.page)
          response.perPage = parseInt(response?.headers?.per_page)
        }

        return response
      },
      (error) => {
        if (error?.response?.status === 401) {
          this.sessionState = null
        }
        return Promise.reject(error)
      }
    )
  }

  storeSessionState(state) {
    storage.local.setItem(this.key, JSON.stringify(state))
  }

  retrieveSessionState() {
    return JSON.parse(storage.local.getItem(this.key))
  }

  get client() {
    return this.api
  }

  get isLoggedIn() {
    return !!JSON.parse(storage.local.getItem(this.key))
  }

  get sessionState() {
    return this.retrieveSessionState()
  }

  set sessionState(state) {
    if (state) {
      const { authorization, userId } = state

      this.api.defaults.headers['Authorization'] = authorization
      this.storeSessionState({ authorization, userId })
    } else {
      delete this.api.defaults.headers['Authorization']
      storage.session.removeItem('returnUrl')
      storage.local.removeItem(this.key)
    }
  }
}

const _api = new ObeApi('__obe_', process.env.NEXT_PUBLIC_API_URL)

export const api = _api

export const client = _api.client
