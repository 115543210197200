import {
  isChrome,
  isEdge,
  isFirefox,
  isSafari,
  isBrowser,
  isMobile,
  isTablet,
} from 'react-device-detect'

export function browserSupportsWorkoutParties() {
  // disabled due to causing error on workout party
  // also, isMediumAndUp may be affecting narrow browsers on desktop,
  // we might need to find a better way to detect mobile.

  return (isChrome || isSafari || isEdge || isFirefox) && isBrowser && !isTablet //&& isMediumAndUp
}

export const browserSupportsFeedFm = () =>
  !isMobile && !isTablet && (isChrome || isSafari || isEdge || isFirefox) && isBrowser

export const getReferrerInfo = () => {
  try {
    const url = new URL(document.referrer)
    const { href, searchParams, pathname } = url
    const query = {}

    for (const [key, value] of searchParams.entries()) {
      query[key] = value
    }

    // URL is not iterable so spreading is not available, if necessary add more properties
    return {
      href,
      pathname,
      query,
    }
  } catch {
    return null
  }
}
