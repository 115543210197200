if (typeof window !== 'undefined') {
  const _setTimeout = window.setTimeout
  const _clearTimeout = window.clearTimeout

  window.timeoutIdMap = new Set()

  window.setTimeout = function (callback, delay) {
    const id = _setTimeout(callback, delay)

    window.timeoutIdMap.add(id)

    return id
  }

  window.clearTimeout = function (id) {
    _clearTimeout(id)

    window.timeoutIdMap.delete(id)
  }

  window.clearAllTimeouts = function () {
    window.timeoutIdMap.forEach((id) => {
      _clearTimeout(id)
    })

    window.timeoutIdMap.clear()
  }
}
