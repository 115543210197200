import * as Sentry from '@sentry/nextjs'
import { api } from 'src/api'
import moment from 'moment'

export const VIDEO_PROGRESS_INTERVAL = 30

class ClassTracker {
  constructor() {
    this.endpoint = process.env.NEXT_PUBLIC_CLASS_TRACKING_URL
  }

  track(event, properties = {}) {
    if (event) {
      const id =
        properties?.videoId ||
        properties?.eventId ||
        properties?.currentLiveClass?.id ||
        properties?.id
      const userId = api.sessionState?.userId || properties?.userId

      const videoType = properties?.currentLiveClass?.id ? 'live' : 'vod'

      const duration =
        videoType === 'vod'
          ? properties.duration
          : properties.duration || properties?.currentLiveClass?.duration

      const videoTitle = (properties.videoTitle || properties?.currentLiveClass?.title)?.trim()

      if (!userId) {
        Sentry.captureException('classTracker - missing userId', {
          level: 'debug',
        })
      }

      if (!id) {
        Sentry.captureException('classTracker - missing properties.id', {
          level: 'debug',
        })
      }

      const payload = {
        userId,
        context: { library: { name: 'analytics.js' } },
        event: event,
        originalTimestamp: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        properties: {
          deviceType: 'web',
          duration,
          id,
          from: properties?.from,
          isAirplay: !!properties?.isAirplay,
          isCasting: !!properties?.isCasting,
          isFireTv: !!properties?.isFireTv,
          isMuted: !!properties?.isMuted,
          isRecommended: !!properties?.isRecommended,
          isRoku: !!properties?.isRoku,
          isWorkoutParty: !!properties?.isWorkoutParty,
          videoTitle,
          videoType,
        },
      }

      console.log(payload)

      fetch(this.endpoint, {
        method: 'POST',
        body: JSON.stringify(payload),
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}

const _classTracker = new ClassTracker()

export const classTracker = _classTracker
