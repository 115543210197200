import { api, client } from 'src/api'
import { getGeoLocationFromHeaders } from 'src/utils/getGeoLocationFromHeaders'

export const acceptUpdatedTos = async (userId, tosVersion) =>
  await client.put(`/api/v3/users/${userId}/accept_updated_tos?tos_version=${tosVersion}`)

export const addOnboardingReferralInfo = async (userId, params) =>
  await client.put(`/api/v3/users/${userId}/add_onboarding_referral`, params)

export const changePassword = async (userId, params) =>
  await client.patch(`/api/v3/users/${userId}/change_password`, params)

export const createUser = async (params) => {
  const response = await client.post('/users.json', params)

  api.sessionState = {
    authorization: response.headers.authorization,
    userId: response.data.id,
  }

  return response
}

export const getClassHistory = async (userId, startTime, endTime, page, perPage = 30) =>
  await client.get(`/api/v3/users/${userId}/class_history`, {
    params: {
      start_time: startTime,
      end_time: endTime,
      per_page: perPage,
      page,
    },
  })

export const getCurrentUser = async () => {
  let response
  let user = {
    currentUser: null,
    geoLocation: null,
  }

  if (api.sessionState) {
    try {
      response = await client.get('/api/v3/users/me')
    } catch (error) {
      api.sessionState = null
      window.location.href = '/login'
    }

    if (response?.data) {
      obe.analytics.identify(response?.data?.id, response?.data?.email)

      // add userId back into existing sessions
      // can be removed after 2+ weeks have passed
      api.sessionState = {
        ...api.sessionState,
        userId: response?.data?.id,
      }

      user = {
        currentUser: response?.data,
        geoLocation: getGeoLocationFromHeaders(response),
      }
    }
  }

  return user
}

export const getEmailContacts = async (emails) =>
  await client.post('/api/v3/users/get_email_contacts', {
    emails,
  })

export const getMostTakenInstructor = (userId) =>
  client.get(`/api/v3/users/${userId}/most_taken_instructor`)

export const getStreak = async (userId) => await client.get(`/api/v3/users/${userId}/streak`)

export const getTopWeekCompletedClasses = (userId) =>
  client.get(`/api/v3/users/${userId}/top_week_completed_classes`)

export const resetPassword = async (password, reset_password_token) =>
  await client.put('/api/passwords.json', {
    user: { password, reset_password_token },
    api_version: 'v3',
  })

export const updateConditionalDisplay = async (userId, conditionalAttributeValues) => {
  const updateJson = {
    user: {
      conditional_display_attributes: conditionalAttributeValues,
    },
  }

  return client.put(`/api/v3/users/${userId}/update_conditional_display_attributes`, updateJson)
}

export const updateFeatureAnnouncements = async (userId, featureAnnouncements) => {
  const updateJson = {
    user: {
      feature_announcements: featureAnnouncements,
    },
  }

  return client.put(`/api/v3/users/${userId}/update_feature_announcements`, updateJson)
}

export const updateNotificationSettings = async (userId, notificationSettingValues) => {
  const updateJson = {
    user: {
      notificationSettings: notificationSettingValues,
    },
  }

  return client.put(`/api/v3/users/${userId}/update_notification_settings`, updateJson)
}
