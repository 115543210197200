import { storage } from 'src/storage'

export const retrieveUtmParameters = () => {
  try {
    return JSON.parse(storage.session.getItem('obe_utm')) || {}
  } catch (error) {
    return {}
  }
}

export const retrieveUtmValue = (key) => {
  const utmParams = JSON.parse(storage.session.getItem('obe_utm')) || {}
  return Object.prototype.hasOwnProperty.call(utmParams, key)
    ? typeof utmParams[key] === 'string'
      ? utmParams[key].toUpperCase()
      : utmParams[key]?.join('').toUpperCase()
    : null
}

export const isSamsungSignup = () => {
  return retrieveUtmParameters()?.utm_source === 'payment_interface_samsung'
}
