module.exports.WEBPACK_ENV_CONFIG = {
  environment: 'localhost',
  chromecast: {
    app_id: process.env.NEXT_PUBLIC_CHROMECAST_APP_ID,
  },
  feedFm: {
    key: process.env.NEXT_PUBLIC_FEEDFM_SECRET,
    token: process.env.NEXT_PUBLIC_FEEDFM_TOKEN,
  },
  filestack: {
    key: process.env.NEXT_PUBLIC_FILESTACK_API_KEY,
  },
  googlePlaces: {
    key: process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY,
  },
  opentok: {
    key: process.env.NEXT_PUBLIC_OPENTOK_API_KEY,
  },
  segment: {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_API_KEY,
    host: 'analytics-cdn.obefitness.com',
    load: false,
    page: false,
  },
  stripe: {
    key: process.env.NEXT_PUBLIC_STRIPE_API_KEY,
  },
}
