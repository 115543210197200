import { getCurrentUser } from 'src/models/user'
import { useRouter } from 'src/hooks/useRouter'
import useSwr, { mutate, useSWRConfig } from 'swr'

export {
  clearCache,
  setAccount,
  setConditionalDisplayAttributes,
  setGeoLocation,
  setMembershipActivity,
  setProfile,
  setSubscription,
  setUser,
} from './setters'

export const USER_KEY = '/api/v3/users/me'
export const loadUser = () => mutate(USER_KEY)

export const useUser = () => {
  const { cache } = useSWRConfig()
  const { asPath } = useRouter()

  const { data, error } = useSwr(USER_KEY, getCurrentUser, {
    revalidateOnMount: !cache.has(USER_KEY) || asPath.includes('plans'),
    revalidateOnFocus: asPath.includes('videos'),
    revalidateOnReconnect: false,
  })

  const isLoadingInitialState = !data && !error

  if (error) {
    return {
      currentUser: null,
      geoLocation: null,
      error,
      isLoading: false,
      isLoadingInitialState,
      ...getUserParams(null, null),
    }
  }

  if (!data) {
    return {
      currentUser: null,
      geoLocation: null,
      isLoading: true,
      isLoadingInitialState,
      ...getUserParams(null, null),
    }
  }

  const { currentUser, geoLocation } = data

  return {
    currentUser,
    isLoading: false,
    isLoadingInitialState,
    ...getUserParams(currentUser, geoLocation),
  }
}

export default useUser

const getUserParams = (currentUser, geoLocation) => ({
  canAccessLevelUpClub: !!currentUser?.account?.perks?.challenges,
  canSelfCancel:
    currentUser?.subscription?.canSelfCancel ||
    (geoLocation?.countryCode === 'US' &&
      ['California', 'New York', 'Vermont'].includes(geoLocation?.region)),
  canUseSocialFeatures: !!currentUser?.account?.active && !!currentUser?.profile?.username,
  geoLocation,
  isCanceled:
    !currentUser?.account?.nonBillable && currentUser?.subscription?.status === 'canceled',
  startDate: currentUser?.subscription?.originalStartDate,
  isGuest: currentUser === null,
  isInactive:
    !currentUser?.account?.nonBillable && currentUser?.subscription?.status === 'inactive',
  isMember: !!currentUser?.account?.active,
  isNonbillable: !!currentUser?.account?.nonBillable,
  isPremiumStatus: ((plan) => plan === 'standard_yearly' || plan === 'standard_quarterly')(
    currentUser?.subscription?.plan
  ),
  isStripeUser: currentUser?.subscription?.paymentGateway === 'stripe',
  hasFinishedGexQuiz:
    !!currentUser?.account?.active &&
    currentUser?.account?.conditionalDisplayAttributes?.hasFinishedGexOnboardingQuiz,
  hasFinishedPtQuiz:
    !!currentUser?.account?.active &&
    currentUser?.account?.conditionalDisplayAttributes?.hasFinishedPtOnboardingQuiz,
  isPtActive:
    !!currentUser?.account?.active &&
    currentUser?.personalTrainingSubscription?.status === 'active',
  showRecStars: currentUser?.account?.conditionalDisplayAttributes?.showRecStars !== false,
  username: currentUser?.profile?.username,
})
