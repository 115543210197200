import * as Sentry from '@sentry/nextjs'
import queryString from 'query-string'
import { storage } from 'src/storage'
import isEmpty from 'lodash.isempty'

const utmKeys = [
  'utm_ad',
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_promo',
  'utm_source',
  'utm_term',
]

export const storeUtmParameters = () => {
  try {
    const allParams = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {}

    const utmParams = Object.keys(allParams)
      .filter((key) => utmKeys.includes(key))
      .reduce((utmParams, key) => {
        utmParams[key] = allParams[key]
        return utmParams
      }, {})

    if (!isEmpty(utmParams)) {
      storage.session.setItem('obe_utm', JSON.stringify(utmParams))
    }
  } catch (error) {
    Sentry.captureException(error, 'warning')
  }
}
